import React from "react"

export const TitleUnderlineRightOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="345" height="9" viewBox="0 0 345 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.9026 8.33102C56.8092 7.42759 4.8012 8.25375 1.01044 7.99818C-2.09109 7.64156 2.6761 1.47805 5.51917 0.568684C6.52429 0.24773 7.70167 0.18235 10.4012 0.354714C12.555 0.485473 27.3735 0.467639 41.675 0.402259C55.9765 0.33688 69.761 0.259614 69.761 0.259614C70.479 0.455753 72.6903 -0.180211 73.0923 0.0515895C73.7241 0.324995 96.3537 0.372544 119.787 0.372544C143.221 0.372544 167.459 0.354711 171.25 0.360654C175.874 0.366598 172.169 0.717274 177.625 0.610289C189.227 0.384432 214.442 0.883691 223.229 0.616229C230.294 0.40226 231.787 0.414147 238.507 0.681609C245.342 0.955015 281.986 0.996621 286.236 0.622174C287.27 0.515189 288.046 0.562737 288.046 0.699439C288.534 0.71727 313.806 1.11549 313.892 1.21653C314.064 1.35324 314.839 1.4305 315.586 1.34135C316.964 1.21059 344.505 1.01445 344.85 1.41862C345.912 2.57762 341.116 7.38004 337.756 8.5331C334.224 9.75154 316.735 8.12894 302.261 8.73518C223.315 8.00412 239.024 8.06356 221.19 8.50338C219.611 8.07544 213.235 9.04425 209.445 8.29536C207.894 7.98629 196.55 7.86742 193.966 8.14677C191.123 8.45584 134.29 7.30277 127.628 8.17054C117.059 7.40976 73.8964 8.19432 63.9026 8.33102Z" fill="#FFBA00"/>
      </svg> 
  </span>
)

export const TitleUnderlineRightTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="56" height="9" viewBox="0 0 56 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.3726 8.33102C9.22121 7.42759 0.779325 8.25375 0.164013 7.99818C-0.339423 7.64156 0.434381 1.47805 0.895865 0.568684C1.05902 0.24773 1.25013 0.18235 1.6883 0.354714C2.03791 0.485473 4.44323 0.467639 6.76463 0.402259C9.08603 0.33688 11.3235 0.259614 11.3235 0.259614C11.4401 0.455753 11.799 -0.180211 11.8643 0.0515895C11.9668 0.324995 15.64 0.372544 19.4438 0.372544C23.2475 0.372544 27.1818 0.354711 27.7971 0.360654C28.5476 0.366598 27.9463 0.717274 28.8319 0.610289C30.7152 0.384432 34.8079 0.883691 36.2343 0.616229C37.381 0.40226 37.6234 0.414147 38.7142 0.681609C39.8236 0.955015 45.7717 0.996621 46.4615 0.622174C46.6294 0.515189 46.7552 0.562737 46.7552 0.699439C46.8345 0.71727 50.9365 1.11549 50.9505 1.21653C50.9785 1.35324 51.1044 1.4305 51.2256 1.34135C51.4493 1.21059 55.9196 1.01445 55.9756 1.41862C56.148 2.57762 55.3696 7.38004 54.8242 8.5331C54.2508 9.75154 51.412 8.12894 49.0626 8.73518C36.2483 8.00412 38.7981 8.06356 35.9034 8.50338C35.647 8.07544 34.6121 9.04425 33.9968 8.29536C33.7451 7.98629 31.9038 7.86742 31.4843 8.14677C31.0228 8.45584 21.7978 7.30277 20.7163 8.17054C19.0009 7.40976 11.9948 8.19432 10.3726 8.33102Z" fill="#FFBA00" />
    </svg>
  </span>
)